import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { Box, Button } from "@mui/material";
import { IconChevronLeft, IconChevronRight } from "@tabler/icons";

const PageContainer = ({ title, description, children, onNext, onBack }) => (
  <>
    <Box display="flex" justifyContent="space-between" mb={2}>
      <Box>
        {onBack && (
          <Button variant="contained" onClick={onBack}>
            <IconChevronLeft /> Previous submission
          </Button>
        )}
      </Box>
      <Box>
        {onNext && (
          <Button variant="contained" onClick={onNext}>
            Next submission <IconChevronRight />
          </Button>
        )}
      </Box>
    </Box>
    <Box
      display="flex"
      flexDirection="column"
      gap={3}
      sx={{
        padding: 2,
        backgroundColor: "#f9f9f9",
        borderRadius: 2,
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
      }}
    >
      {children}
    </Box>
  </>
);

PageContainer.propTypes = {
  title: PropTypes.node,
  description: PropTypes.string,
  children: PropTypes.node,
  onNext: PropTypes.func,
  onBack: PropTypes.func,
};

export default PageContainer;
