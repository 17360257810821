import { createSlice } from "@reduxjs/toolkit";
import {
  getRequestedReviews,
  getReviews,
  getUnseenReviews,
} from "./actions/reviewActions";

const reviewSlice = createSlice({
  name: "review",
  initialState: {
    reviews: {
      loading: false,
      data: null,
    },
    requestedReviews: {
      loading: false,
      data: null,
    },
    unseenReviews: {
      loading: false,
      data: null,
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getReviews.pending, (state, action) => {
      state.reviews.loading = true;
    });
    builder.addCase(getReviews.fulfilled, (state, action) => {
      state.reviews.loading = false;
      state.reviews.data = action.payload;
    });
    builder.addCase(getReviews.rejected, (state, action) => {
      state.reviews.loading = false;
    });
    builder.addCase(getRequestedReviews.pending, (state, action) => {
      state.requestedReviews.loading = true;
    });
    builder.addCase(getRequestedReviews.fulfilled, (state, action) => {
      state.requestedReviews.loading = false;
      state.requestedReviews.data = action.payload;
    });
    builder.addCase(getRequestedReviews.rejected, (state, action) => {
      state.requestedReviews.loading = false;
    });
    builder.addCase(getUnseenReviews.pending, (state, action) => {
      state.unseenReviews.loading = true;
    });
    builder.addCase(getUnseenReviews.fulfilled, (state, action) => {
      state.unseenReviews.loading = false;
      state.unseenReviews.data = action.payload;
    });
  },
});

export default reviewSlice.reducer;
