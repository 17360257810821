import { createSlice } from "@reduxjs/toolkit";
import {
  getProjects,
  getProject,
  createProject,
  updateProject,
  deleteProject,
  addSubmissionToProject,
} from "./actions/projectActions";

const projectSlice = createSlice({
  name: "project",
  initialState: {
    projects: {
      data: [],
      loading: false,
      error: null,
    },
    currentProject: {
      data: null,
      loading: false,
      error: null,
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProjects.pending, (state) => {
        state.projects.loading = true;
      })
      .addCase(getProjects.fulfilled, (state, action) => {
        state.projects.loading = false;
        state.projects.data = action.payload;
      })
      .addCase(getProjects.rejected, (state, action) => {
        state.projects.loading = false;
        state.projects.error = action.payload;
      })
      .addCase(getProject.pending, (state) => {
        state.currentProject.loading = true;
      })
      .addCase(getProject.fulfilled, (state, action) => {
        state.currentProject.loading = false;
        state.currentProject.data = action.payload;
      })
      .addCase(getProject.rejected, (state, action) => {
        state.currentProject.loading = false;
        state.currentProject.error = action.payload;
      })
      .addCase(createProject.fulfilled, (state, action) => {
        state.projects.data?.push?.(action.payload);
      })
      .addCase(updateProject.fulfilled, (state, action) => {
        const index = state.projects.data?.findIndex(
          (project) => project.id === action.payload.id
        );
        if (index !== -1) {
          state.projects.data[index] = action.payload;
        }
        if (state.currentProject?.data?.id === action.payload.id) {
          state.currentProject.data = action.payload;
        }
      })
      .addCase(deleteProject.fulfilled, (state, action) => {
        state.projects.data = state.projects?.data?.filter?.(
          (project) => project.id !== action.payload
        );
        if (state.currentProject?.data?.id === action.payload) {
          state.currentProject.data = null;
        }
      })
      .addCase(addSubmissionToProject.fulfilled, (state, action) => {
        if (state.currentProject?.data?.id === action.payload.projectId) {
          state.currentProject.data.submissions.push(action.payload);
        }
      });
  },
});

export default projectSlice.reducer;
