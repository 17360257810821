import jwtDecode from "jwt-decode";
//
import axios from "./axios";

// ----------------------------------------------------------------------

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode(accessToken);
  if (decoded) {
    return true;
  }
  return false;
};

const setSession = (session) => {
  if (session?.token) {
    localStorage.setItem("accessToken", session.token);
    axios.defaults.headers.common.Authorization = `Bearer ${session.token}`;
    localStorage.setItem("userRole", session.role);
  } else {
    delete axios.defaults.headers.common.Authorization;
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userRole");
  }
};

export { isValidToken, setSession };
