import { createSlice } from "@reduxjs/toolkit";
import { getAllStudios, getStudio, getStudios } from "./actions/studioActions";

const studioSlice = createSlice({
  name: "studio",
  initialState: {
    studio: {
      loading: false,
      data: null,
    },
    studios: {
      loading: false,
      data: null,
    },
    allStudios: {
      loading: false,
      data: null,
    }
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getStudio.pending, (state, action) => {
      state.studio.loading = true;
    });
    builder.addCase(getStudio.fulfilled, (state, action) => {
      state.studio.loading = false;
      state.studio.data = action.payload;
    });
    builder.addCase(getStudio.rejected, (state, action) => {
      state.studio.loading = false;
    });
    builder.addCase(getStudios.pending, (state, action) => {
      state.studios.loading = true;
    });
    builder.addCase(getStudios.fulfilled, (state, action) => {
      state.studios.loading = false;
      state.studios.data = action.payload;
    });
    builder.addCase(getStudios.rejected, (state, action) => {
      state.studios.loading = false;
    });
    builder.addCase(getAllStudios.pending, (state, action) => {
      state.allStudios.loading = true;
    });
    builder.addCase(getAllStudios.fulfilled, (state, action) => {
      state.allStudios.loading = false;
      state.allStudios.data = action.payload;
    });
    builder.addCase(getAllStudios.rejected, (state, action) => {
      state.allStudios.loading = false;
    })
  },
});

export default studioSlice.reducer;
