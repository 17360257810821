import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axios";

export const getStudio = createAsyncThunk(
  "get/studio",
  async (params, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}/studio`,
        { params }
      );
      return res.data;
    } catch (err) {
      throw rejectWithValue(err.response.data);
    }
  }
);

export const getStudios = createAsyncThunk(
  "get/studios",
  async (params, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}/studios`,
        { params }
      );
      return res.data;
    } catch (err) {
      throw rejectWithValue(err.response.data);
    }
  }
);

export const getAllStudios = createAsyncThunk(
  "get/allstudios",
  async (params, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}/studios/all`,
        { params }
      );
      return res.data;
    } catch (err) {
      throw rejectWithValue(err.response.data);
    }
  }
);

export const createStudio = createAsyncThunk(
  "create/studio",
  async (params, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}/studio`,
        params
      );
      return res.data;
    } catch (err) {
      throw rejectWithValue(err.response.data);
    }
  }
);

export const updateStudio = createAsyncThunk(
  "update/studio",
  async (params, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.put(
        `${process.env.REACT_APP_API_URL}/studio`,
        params
      );
      return res.data;
    } catch (err) {
      throw rejectWithValue(err.response.data);
    }
  }
);
