import axios from "axios";

const axiosInstance = axios.create({
  baseURL: "",
});

axiosInstance.interceptors.request.use((config) => {
  if (localStorage.getItem("accessToken2")) {
    config.headers["Authorization"] = `Bearer ${localStorage.getItem(
      "accessToken2"
    )}`;
  } else {
    const accessToken = localStorage.getItem("accessToken");
    config.headers["Authorization"] = `Bearer ${accessToken}`;
  }
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    // if (error.response.status === 403) {
    //   window.location.href = "/";
    // }
    if (error.response.status === 401) {
      localStorage.removeItem("accessToken");
      window.location.href = "/auth/login";
    }
    return Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    );
  }
);

export default axiosInstance;
