import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import {
  createProject,
  updateProject,
  getProject,
} from "src/redux/actions/projectActions";
import PageContainer from "src/components/container/PageContainer";
import PageTitle from "src/components/title/PageTitle";

const ProjectFormPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const isEditing = !!id;

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const { data: currentProject, loading: projectLoading } = useSelector(
    (state) => state.project.currentProject
  );

  useEffect(() => {
    if (isEditing) {
      dispatch(getProject(id));
    }
  }, [dispatch, id, isEditing]);

  useEffect(() => {
    if (isEditing && currentProject) {
      setName(currentProject.name);
      setDescription(currentProject.description);
    }
  }, [currentProject, isEditing]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      if (isEditing) {
        await dispatch(updateProject({ id, name, description, file })).unwrap();
      } else {
        await dispatch(createProject({ name, description, file })).unwrap();
      }
      navigate(`/projects`);
    } catch (error) {
      console.error(
        `Failed to ${isEditing ? "update" : "create"} project:`,
        error
      );
    } finally {
      setLoading(false);
    }
  };

  if (isEditing && projectLoading) {
    return <Typography>Loading project data...</Typography>;
  }

  return (
    <PageContainer
      title={isEditing ? "Edit Project" : "Create Project"}
      description={
        isEditing ? "Edit an existing project" : "Create a new project"
      }
    >
      <PageTitle title={isEditing ? "Edit Project" : "Create Project"} />
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          mt: 3,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <TextField
          fullWidth
          label="Project Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
          sx={{ mb: 2 }}
        />
        <TextField
          fullWidth
          label="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          multiline
          rows={4}
          sx={{ mb: 2 }}
        />
        <input
          accept="image/*"
          type="file"
          onChange={(e) => setFile(e.target.files[0])}
          style={{ display: "none" }}
          id="project-file-upload"
        />
        {/* <label htmlFor="project-file-upload">
          <Button variant="contained" component="span" sx={{ mb: 2 }}>
            {isEditing ? "Update Project Image" : "Upload Project Image"}
          </Button>
        </label> */}
        {file && (
          <Typography variant="body2" sx={{ mb: 2 }}>
            Selected file: {file.name}
          </Typography>
        )}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={loading || !name}
          sx={{ mt: 2 }}
        >
          {loading ? (
            <CircularProgress size={24} />
          ) : isEditing ? (
            "Update Project"
          ) : (
            "Create Project"
          )}
        </Button>
      </Box>
    </PageContainer>
  );
};

export default ProjectFormPage;
