import { lazy } from "react";
import { Navigate } from "react-router-dom";
import StudiosPage from "src/views/pages/StudiosPage";
import Loadable from "../layouts/full/shared/loadable/Loadable";
import StudioUsersPage from "src/views/pages/StudioUsersPage";
import ProjectFormPage from "../views/pages/ProjectFormPage";
import UserRatingPage from "../views/pages/UserRatingPage";

/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import("../layouts/full/FullLayout")));
const BlankLayout = Loadable(
  lazy(() => import("../layouts/blank/BlankLayout"))
);

/* ****Pages***** */
// const Dashboard = Loadable(lazy(() => import("../views/dashboard/Dashboard")));
const Error = Loadable(lazy(() => import("../views/authentication/Error")));
const Register = Loadable(
  lazy(() => import("../views/authentication/Register"))
);
const Login = Loadable(lazy(() => import("../views/authentication/Login")));
const SubmissionsPage = Loadable(
  lazy(() => import("src/views/pages/SubmissionsPage"))
);
const SubmissionPage = Loadable(
  lazy(() => import("src/views/pages/SubmissionPage"))
);
const StudioPage = Loadable(lazy(() => import("../views/pages/StudioPage")));
const ReviewsPage = Loadable(lazy(() => import("../views/pages/ReviewsPage")));
const CreateSubmissionPage = Loadable(
  lazy(() => import("../views/pages/CreateSubmissionPage"))
);
const CreateStudioPage = Loadable(
  lazy(() => import("../views/pages/CreateStudioPage"))
);
const UsersPage = Loadable(lazy(() => import("../views/pages/UsersPage")));
const UpdatePasswordPage = Loadable(
  lazy(() => import("../views/pages/UpdatePasswordPage"))
);
const ChatsPage = Loadable(lazy(() => import("../views/pages/ChatsPage")));
const ProjectsPage = Loadable(
  lazy(() => import("../views/pages/ProjectsPage"))
);
const CreateProjectPage = Loadable(
  lazy(() => import("../views/pages/CreateProjectPage"))
);
const ProjectPage = Loadable(lazy(() => import("../views/pages/ProjectPage")));

const Router = [
  {
    path: "/",
    element: <FullLayout />,
    children: [
      { path: "/", element: <Navigate to="/submissions" /> },
      // { path: "/dashboard", exact: true, element: <Dashboard /> },
      { path: "/users", exact: true, element: <UsersPage /> },
      {
        path: "/update-password",
        exact: true,
        element: <UpdatePasswordPage />,
      },
      {
        path: "/update-password/:id",
        exact: true,
        element: <UpdatePasswordPage />,
      },
      { path: "/chats", exact: true, element: <ChatsPage /> },
      { path: "/studio", exact: true, element: <StudioPage /> },
      { path: "/studios", exact: true, element: <StudiosPage /> },
      { path: "/studios/:id", exact: true, element: <StudioPage /> },
      { path: "/studios/:id/users", exact: true, element: <StudioUsersPage /> },
      { path: "/studios/create", exact: true, element: <CreateStudioPage /> },
      { path: "/submissions", exact: true, element: <SubmissionsPage /> },
      { path: "/submissions/:id", exact: true, element: <SubmissionPage /> },
      {
        path: "/submissions/create",
        exact: true,
        element: <CreateSubmissionPage />,
      },
      { path: "/reviews", element: <ReviewsPage /> },
      { path: "/projects", exact: true, element: <ProjectsPage /> },
      { path: "/projects/create", element: <ProjectFormPage /> },
      { path: "/projects/:id", element: <ProjectPage /> },
      { path: "/projects/:id/edit", element: <ProjectFormPage /> }, // Add this line
      { path: "/user/:id/ratings", element: <UserRatingPage /> },
      { path: "*", element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: "/auth",
    element: <BlankLayout />,
    children: [
      { path: "404", element: <Error /> },
      { path: "/auth/register/:id", element: <Register /> },
      { path: "/auth/login", element: <Login /> },
      { path: "*", element: <Navigate to="/auth/404" /> },
    ],
  },
];

export default Router;
