import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axios";

export const getProjects = createAsyncThunk(
  "get/projects",
  async (params, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}/projects`,
        { params }
      );
      return res.data;
    } catch (err) {
      throw rejectWithValue(err.response.data);
    }
  }
);

export const getProject = createAsyncThunk(
  "get/project",
  async (id, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}/project/${id}`
      );
      return res.data;
    } catch (err) {
      throw rejectWithValue(err.response.data);
    }
  }
);

export const createProject = createAsyncThunk(
  "create/project",
  async (params, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      const { file, ...data } = params;
      formData.append("data", JSON.stringify(data));
      if (file) {
        formData.append("file", file, file.name);
      }
      const res = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}/project`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return res.data;
    } catch (err) {
      throw rejectWithValue(err.response.data);
    }
  }
);

export const updateProject = createAsyncThunk(
  "update/project",
  async (params, { rejectWithValue }) => {
    try {
      const { id, ...data } = params;
      const formData = new FormData();
      formData.append("data", JSON.stringify(data));
      if (data.file) {
        formData.append("file", data.file, data.file.name);
      }
      const res = await axiosInstance.put(
        `${process.env.REACT_APP_API_URL}/project/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return res.data;
    } catch (err) {
      throw rejectWithValue(err.response.data);
    }
  }
);

export const deleteProject = createAsyncThunk(
  "delete/project",
  async (id, { rejectWithValue }) => {
    try {
      await axiosInstance.delete(
        `${process.env.REACT_APP_API_URL}/project/${id}`
      );
      return id;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const addSubmissionToProject = createAsyncThunk(
  "add/submissionToProject",
  async (params, { rejectWithValue }) => {
    try {
      const { projectId, submissionId } = params;
      const res = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}/project/${projectId}/submission`,
        { submissionId }
      );
      return res.data;
    } catch (err) {
      throw rejectWithValue(err.response.data);
    }
  }
);
