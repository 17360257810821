import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axios";

export const getReviews = createAsyncThunk(
  "get/ratings",
  async (params, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}/reviews`,
        { params }
      );
      return res.data;
    } catch (err) {
      throw rejectWithValue(err.response.data);
    }
  }
);

export const updateReviews = createAsyncThunk(
  "update/rating",
  async (params, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.put(
        `${process.env.REACT_APP_API_URL}/review`,
        params
      );
      return res.data;
    } catch (err) {
      throw rejectWithValue(err.response.data);
    }
  }
);

export const createReview = createAsyncThunk(
  "create/rating",
  async (params, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}/review`,
        params
      );
      return res.data;
    } catch (err) {
      throw rejectWithValue(err.response.data);
    }
  }
);

export const deleteReview = createAsyncThunk(
  "delete/rating",
  async (params, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.delete(
        `${process.env.REACT_APP_API_URL}/review`,
        { params }
      );
      return res.data;
    } catch (err) {
      throw rejectWithValue(err.response.data);
    }
  }
);

export const getRequestedReviews = createAsyncThunk(
  "get/requestedReviews",
  async (params, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}/reviews/requested`,
        { params }
      );
      return res.data;
    } catch (err) {
      throw rejectWithValue(err.response.data);
    }
  }
);

export const getUnseenReviews = createAsyncThunk(
  "get/unseenReviews",
  async (params, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}/reviews/unseen`,
        { params }
      );
      return res.data;
    } catch (err) {
      throw rejectWithValue(err.response.data);
    }
  }
);

export const markReviewAsSeen = createAsyncThunk(
  "mark/reviewAsSeen",
  async (params, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.put(
        `${process.env.REACT_APP_API_URL}/review/seen`,
        params
      );
      return res.data;
    } catch (err) {
      throw rejectWithValue(err.response.data);
    }
  }
);
