import React from "react";
import {
  Box,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Paper,
  TableContainer,
} from "@mui/material";
import { IconChevronDown, IconChevronUp } from "@tabler/icons";
import DashboardCard from "../shared/DashboardCard";

const groupDataByDate = (data) => {
  const groupedData = {
    Today: [],
    Yesterday: [],
    "Previous 7 Days": [],
    "Previous 30 Days": [],
    Older: [],
  };

  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);
  const sevenDaysAgo = new Date(today);
  sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
  const thirtyDaysAgo = new Date(today);
  thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

  data?.forEach((row) => {
    const rowDate = new Date(row.createdat || row.createdAt);
    if (rowDate.toDateString() === today.toDateString()) {
      groupedData.Today.push(row);
    } else if (rowDate.toDateString() === yesterday.toDateString()) {
      groupedData.Yesterday.push(row);
    } else if (rowDate >= sevenDaysAgo) {
      groupedData["Previous 7 Days"].push(row);
    } else if (rowDate >= thirtyDaysAgo) {
      groupedData["Previous 30 Days"].push(row);
    } else {
      groupedData.Older.push(row);
    }
  });

  return groupedData;
};

const GenericTable = ({
  title,
  columns,
  action,
  data,
  count,
  loading,
  sortBy,
  setSortBy,
  direction,
  setDirection,
  page,
  setPage,
  limit,
  setLimit,
  groupByDate,
}) => {
  const groupedData = groupByDate ? groupDataByDate(data) : { All: data };

  return (
    <DashboardCard title={title} action={action}>
      <Box sx={{ overflow: "auto", width: { xs: "280px", sm: "auto" } }}>
        <TableContainer component={Paper} sx={{ mt: 2, borderRadius: 2 }}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                {columns?.map((column) => (
                  <TableCell
                    key={column.id}
                    onClick={() => {
                      if (!column.sortable) return;

                      setSortBy(column.id);

                      if (column.id === sortBy) {
                        setDirection(direction === "asc" ? "desc" : "asc");
                      } else {
                        setDirection("asc");
                      }
                    }}
                    sx={{
                      cursor: "pointer",
                      "&:hover": {
                        color: "primary.main",
                      },
                      fontWeight: "bold",
                    }}
                  >
                    <Box display="flex" alignItems="center">
                      <Typography variant="subtitle2" fontWeight={600}>
                        {column.label}
                      </Typography>
                      {column.id === sortBy &&
                        (direction === "asc" ? (
                          <IconChevronUp />
                        ) : (
                          <IconChevronDown />
                        ))}
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {loading &&
                Array(10)
                  .fill(0)
                  .map((_, i) => (
                    <TableRow key={i}>
                      {columns?.map((column) => (
                        <TableCell key={column.id} align={column.align}>
                          <Skeleton
                            variant="rounded"
                            sx={{ height: 20, borderRadius: 1 }}
                          />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
              {!loading &&
                Object.keys(groupedData).map((group) => (
                  <React.Fragment key={group}>
                    {group !== "All" && (
                      <TableRow>
                        <TableCell
                          colSpan={columns?.length}
                          sx={{ backgroundColor: "#e0e0e0", borderRadius: 1 }}
                        >
                          <Typography variant="h6">{group}</Typography>
                        </TableCell>
                      </TableRow>
                    )}
                    {groupedData[group]?.length > 0 ? (
                      groupedData[group]?.map((row, index) => (
                        <TableRow
                          key={row.name}
                          sx={{
                            backgroundColor:
                              index % 2 === 0 ? "#f9f9f9" : "#ffffff",
                            borderRadius: 1,
                          }}
                        >
                          {columns?.map((column) => (
                            <TableCell key={column.id}>
                              {column.format(row)}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={columns?.length} align="center">
                          <Typography variant="body2">None</Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </React.Fragment>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={count}
          rowsPerPage={limit}
          page={page}
          onPageChange={(e, page) => setPage(page)}
          onRowsPerPageChange={(e) => setLimit(e.target.value)}
        />
      </Box>
    </DashboardCard>
  );
};

export default GenericTable;
