import {
  CssBaseline,
  ThemeProvider,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Box,
} from "@mui/material";
import { useRoutes } from "react-router-dom";
import Router from "./routes/Router";
import { useState, useEffect } from "react";

import { baselightTheme } from "./theme/DefaultColors";
import { IconX } from "@tabler/icons";

function App() {
  const routing = useRoutes(Router);
  const theme = baselightTheme;
  const [isImpersonating, setIsImpersonating] = useState(false);

  useEffect(() => {
    const accessToken2 = localStorage.getItem("accessToken2");
    setIsImpersonating(!!accessToken2);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      {isImpersonating && (
        <AppBar
          position="fixed"
          color="secondary"
          sx={{ zIndex: 1301, backgroundColor: "secondary.dark" }}
        >
          <Toolbar sx={{ justifyContent: "space-between" }}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", color: "white" }}
              >
                Impersonating User {}
              </Typography>
            </Box>
            <IconButton
              onClick={() => {
                localStorage.removeItem("accessToken2");
                localStorage.removeItem("userRole2");
                window.location.href = "/";
              }}
              color="inherit"
            >
              <IconX />
            </IconButton>
          </Toolbar>
        </AppBar>
      )}
      <CssBaseline />
      <Box sx={{ mt: isImpersonating ? 8 : 0 }}>{routing}</Box>
    </ThemeProvider>
  );
}

export default App;
