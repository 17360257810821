import { Box, Typography } from "@mui/material";
import React from "react";

const PageTitle = ({ title, action }) => {
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Typography variant="h3">{title}</Typography>
      {action}
    </Box>
  );
};

export default PageTitle;
