import { createSlice } from "@reduxjs/toolkit";
import { getSubmission, getSubmissions, addComment, getComments } from "./actions/submissionActions";

const submissionSlice = createSlice({
  name: "submission",
  initialState: {
    submissions: {
      data: null,
      loading: false,
    },
    submission: {
      data: null,
      loading: false,
    },
    comments: {
      data: [],
      loading: false,
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSubmissions.pending, (state, action) => {
      state.submissions.loading = true;
    });
    builder.addCase(getSubmissions.fulfilled, (state, action) => {
      state.submissions.loading = false;
      state.submissions.data = action.payload;
    });
    builder.addCase(getSubmissions.rejected, (state, action) => {
      state.submissions.loading = false;
    });
    builder.addCase(getSubmission.pending, (state, action) => {
      state.submission.loading = true;
    });
    builder.addCase(getSubmission.fulfilled, (state, action) => {
      state.submission.loading = false;
      state.submission.data = action.payload;
    });
    builder.addCase(getSubmission.rejected, (state, action) => {
      state.submission.loading = false;
    });
    builder.addCase(addComment.fulfilled, (state, action) => {
      state.submission.data.comments.unshift(action.payload);
    });
    builder.addCase(getComments.pending, (state) => {
      state.comments.loading = true;
    });
    builder.addCase(getComments.fulfilled, (state, action) => {
      state.comments.loading = false;
      state.comments.data = action.payload;
    });
    builder.addCase(getComments.rejected, (state) => {
      state.comments.loading = false;
    });
  },
});

export default submissionSlice.reducer;
