import { configureStore } from "@reduxjs/toolkit";
import reviewSlice from "./reviewSlice";
import studioSlice from "./studioSlice";
import submissionSlice from "./submissionSlice";
import userSlice from "./userSlice";
import projectSlice from "./projectSlice";
const store = configureStore({
  reducer: {
    user: userSlice,
    studio: studioSlice,
    submission: submissionSlice,
    review: reviewSlice,
    project: projectSlice,
  },
});

export default store;
