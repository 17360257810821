import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axios";

export const getSubmissions = createAsyncThunk(
  "get/submissions",
  async (params, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}/submissions`,
        { params }
      );
      return res.data;
    } catch (err) {
      throw rejectWithValue(err.response.data);
    }
  }
);

export const getSubmission = createAsyncThunk(
  "get/submission",
  async (params, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}/submission`,
        { params }
      );
      return res.data;
    } catch (err) {
      throw rejectWithValue(err.response.data);
    }
  }
);

export const createSubmission = createAsyncThunk(
  "create/submission",
  async (params, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      const { file, data } = params;
      formData.append("data", JSON.stringify(data));
      if (file) {
        formData.append("file", file, file.name);
      }
      const res = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}/submission`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return res.data;
    } catch (err) {
      throw rejectWithValue(err.response.data);
    }
  }
);

export const updateSubmission = createAsyncThunk(
  "update/submission",
  async (params, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.put(
        `${process.env.REACT_APP_API_URL}/submission`,
        params
      );
      return res.data;
    } catch (err) {
      throw rejectWithValue(err.response.data);
    }
  }
);

export const addComment = createAsyncThunk(
  "add/comment",
  async (params, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      const { file, data } = params;
      formData.append("data", JSON.stringify(data));
      if (file) {
        formData.append("file", file, file.name);
      }
      const res = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}/submission/comment`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return res.data;
    } catch (err) {
      throw rejectWithValue(err.response.data);
    }
  }
);

export const getComments = createAsyncThunk(
  "get/comments",
  async (submissionId, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}/submission/${submissionId}/comments`
      );
      return res.data;
    } catch (err) {
      throw rejectWithValue(err.response.data);
    }
  }
);
