import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography, Paper } from "@mui/material";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import PageContainer from "src/components/container/PageContainer";
import { getUserRatings } from "src/redux/actions/userActions";

const UserRatingPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const { ratings, loading } = useSelector((state) => ({
    ratings: state.user.userRatings.data,
    loading: state.user.userRatings.loading,
  }));

  useEffect(() => {
    if (id) {
      dispatch(getUserRatings(id));
    }
  }, [dispatch, id]);

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <PageContainer title="Rating Evolution" description="User rating evolution">
      <Paper sx={{ p: 3 }}>
        <Typography variant="h4" sx={{ mb: 3 }}>
          Rating Evolution
        </Typography>

        <Box sx={{ width: "100%", height: 400 }}>
          <ResponsiveContainer>
            <LineChart
              data={ratings}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="week"
                tickFormatter={(date) => new Date(date).toLocaleDateString()}
              />
              <YAxis
                domain={[0, 5]}
                tickFormatter={(value) => value.toLocaleString()}
              />
              <Tooltip
                labelFormatter={(date) => new Date(date).toLocaleDateString()}
                formatter={(value) => value.toLocaleString()}
              />
              <Legend />
              <Line
                type="monotone"
                dataKey="rating"
                stroke="#8884d8"
                name="Rating"
                dot={false}
              />
              {Object.keys(ratings?.[0]?.subratings || {}).map((category) => (
                <Line
                  key={category}
                  type="monotone"
                  dataKey={(data) => data.subratings[category]}
                  name={category}
                  dot={false}
                />
              ))}
            </LineChart>
          </ResponsiveContainer>
        </Box>
      </Paper>
    </PageContainer>
  );
};

export default UserRatingPage;
