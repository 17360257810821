import { useState } from "react";

const useTable = () => {
  const [sortBy, setSortBy] = useState("id");
  const [direction, setDirection] = useState("desc");
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(100);

  return {
    sortBy,
    setSortBy,
    direction,
    setDirection,
    page,
    setPage,
    limit,
    setLimit,
  };
};

export default useTable;
